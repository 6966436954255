// Override default variables before the import
// $body-bg: #000;

.module-title-block {
  display: block;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #999999;
}

.module-title-block h1 {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
}

.module-title-block a {
  font-size: 10px;
}

$body-color: #000000;

$blue: #1bc1e5 !default;
$orange: #ed6921 !default;
$darkblue: #2861ab !default;
$red: #ff7578 !default;

$colors: () !default;
$colors: map-merge(
  (
    'blue': $blue,
    'darkblue': $darkblue,
    'orange': $orange,
  ),
  $colors
);

$primary: $orange !default;
$secondary: $orange !default;
// $secondary: $gray-600 !default;

$btn-font-size: 0.8rem !default;
$btn-padding-x: 2rem;

$btn-border-radius: 1.5rem !default;
$input-border-radius: 1.5rem !default;

$table-accent-bg: #454a5c13;
$table-hover-bg: #454a5c41;
// $btn-border-radius-lg:        $border-radius-lg !default;
// $btn-border-radius-sm:        $border-radius-sm !default;

.table {
  // width: 100%;
  // margin-bottom: $spacer;
  // color: $table-color;
  // background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    // padding: $table-cell-padding;
    vertical-align: middle;
    // border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    // vertical-align: bottom;
    // border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    // border-top: (2 * $table-border-width) solid $table-border-color;
  }
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.table th,
.table td {
  vertical-align: middle;
}
