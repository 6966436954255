#root {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* --------------------------------------- */
/* Font definitions  */
/* --------------------------------------- */
/* --------------------------------------- */
/* --------------------------------------- */
/* --------------------------------------- */

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?2tqqe9');
  src: url('fonts/icomoon.eot?2tqqe9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?2tqqe9') format('truetype'),
    url('fonts/icomoon.woff?2tqqe9') format('woff'),
    url('fonts/icomoon.svg?2tqqe9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.8rem;
  vertical-align: middle;
  margin-right: 5px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-App__Icon_Curry:before {
  content: '\e921';
}
.icon-App__Icon_Game:before {
  content: '\e922';
}
.icon-App__Icon_Pasta:before {
  content: '\e923';
}
.icon-App__Icon_plantfood:before {
  content: '\e924';
}
.icon-App__Icon_SeaFood:before {
  content: '\e925';
}
.icon-App__Icon_Veal:before {
  content: '\e926';
}
.icon-App__Icon_Bottle:before {
  content: '\e927';
}
.icon-App__Icon_Can:before {
  content: '\e928';
}
.icon-App__Icon_Cheese:before {
  content: '\e929';
}
.icon-App__Icon_Cured-meat:before {
  content: '\e92a';
}
.icon-App__Icon_Draft:before {
  content: '\e92b';
}
.icon-App__Icon_Poltry:before {
  content: '\e92c';
}
.icon-App__Icon_Pork:before {
  content: '\e92d';
}
.icon-App__Icon_Sheelfish:before {
  content: '\e92e';
}
.icon-eye:before {
  content: '\e91f';
}
.icon-eye-slash:before {
  content: '\e920';
}
.icon-new_Icon_Dietary:before {
  content: '\e91b';
}
.icon-new_Icon_UnderKcal:before {
  content: '\e91c';
}
.icon-new_Icon_Vegan:before {
  content: '\e91d';
}
.icon-new_Icon_Vegetarian:before {
  content: '\e91e';
}
.icon-LifeWithout_Icon_Beer:before {
  content: '\e900';
}
.icon-LifeWithout_Icon_Cider:before {
  content: '\e901';
}
.icon-LifeWithout_Icon_Dontlike .path1:before {
  content: '\e902';
  color: rgb(229, 28, 36);
}
.icon-LifeWithout_Icon_Dontlike .path2:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-LifeWithout_Icon_Dontlike .path3:before {
  content: '\e904';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-LifeWithout_Icon_Edit:before {
  content: '\e905';
}
.icon-LifeWithout_Icon_Favorite .path1:before {
  content: '\e906';
  color: rgb(132, 190, 63);
}
.icon-LifeWithout_Icon_Favorite .path2:before {
  content: '\e907';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-LifeWithout_Icon_Foodpairing:before {
  content: '\e908';
}
.icon-LifeWithout_Icon_Gin:before {
  content: '\e909';
}
.icon-LifeWithout_Icon_Light:before {
  content: '\e90a';
}
.icon-LifeWithout_Icon_Like:before {
  content: '\e90b';
}
.icon-LifeWithout_Icon_LikeFill:before {
  content: '\e90c';
}
.icon-LifeWithout_Icon_Liste:before {
  content: '\e90d';
}
.icon-LifeWithout_Icon_Loation:before {
  content: '\e90e';
}
.icon-LifeWithout_Icon_Notification:before {
  content: '\e90f';
}
.icon-LifeWithout_Icon_Othertick:before {
  content: '\e910';
}
.icon-LifeWithout_Icon_Pourcentage:before {
  content: '\e911';
}
.icon-LifeWithout_Icon_Profile:before {
  content: '\e912';
}
.icon-LifeWithout_Icon_Search:before {
  content: '\e913';
}
.icon-LifeWithout_Icon_Setting:before {
  content: '\e914';
}
.icon-LifeWithout_Icon_Star:before {
  content: '\e915';
}
.icon-LifeWithout_Icon_StarFill:before {
  content: '\e916';
}
.icon-LifeWithout_Icon_Style:before {
  content: '\e917';
}
.icon-LifeWithout_Icon_Taste:before {
  content: '\e918';
}
.icon-LifeWithout_Icon_Tick:before {
  content: '\e919';
}
.icon-LifeWithout_Icon_Wine:before {
  content: '\e91a';
}
