.bw-form-control {
  margin: 16px 0;
}

.bw-form-label {
  font-size: 14px;
  font-weight: 500;
}

.bw-form-input {
  border-radius: 16px;
  border: 0;
  background-color: #f2f2f2;
  width: 100%;
  padding: 6px 16px;
  font-size: 14px;
}

.bw-form-select {
  border-radius: 16px;
  border: 0;
  background-color: #f2f2f2;
  width: 100%;
  padding: 6px 16px;
  font-size: 14px;
}

.bw-form-input:focus {
}
